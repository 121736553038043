import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
    Box,
    Button, IconButton, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    MenuItem, SelectChangeEvent, FormControl, InputLabel, Link
} from '@mui/material';

import Label from 'src/components/Label';
import Shop from "../../../models/shop";
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';
import Order from 'src/models/order';

const ListOrdersTable: FC = () => {
    const [orders, setOrders] = useState<Array<Order>>([]);
    const [currentOrder, setCurrentOrder] = useState<Order>();
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const getOrders = (page: number, pageSize: number) => {
        const headers = { 'token': JSON.parse(localStorage.getItem("token")).token }
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/orders?page=${page}&pageSize=${pageSize}`, { headers })
            .then(async response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    const data = await response.json();
                    if (data) {
                        setOrders(data.data.orders)
                        setTotal(data.data.total)
                    } else {
                        setOrders([])
                    }
                }
            })
    }

    useEffect(() => {
        getOrders(page, pageSize);
    }, [page, pageSize]);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setPageSize(parseInt(event.target.value));
    };

    const getStatusLabel = (status: string): JSX.Element => {
        if (status === 'BILL_IMG_UPLOADED') {
            return <Label color={"warning"}>Đã có ảnh đơn hàng</Label>;
        }
        if (status === 'REVIEW_IMG_UPLOADED') {
            return <Label color={"primary"}>Đã có ảnh review</Label>;
        }
        if (status === 'REJECTED') {
            return <Label color={"error"}>Đã từ chối</Label>;
        }
        if (status === 'REFUNDED') {
            return <Label color={"success"}>Đã hoàn tiền</Label>;
        }
        if (status === 'HOLDING') {
            return <Label color={"info"}>Đang giữ chỗ</Label>;
        }
        if (status === 'EXPIRED') {
            return <Label color={"secondary"}>Đã quá hạn</Label>;
        }
        return <Label></Label>
    }

    return (
        <TableContainer style={{ padding: 20 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Shop</TableCell>
                        <TableCell>Product name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Dialog open={openDialog}>
                        <IconButton onClick={() => {
                            setOpenDialog(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                        <ul style={{ padding: 10 }}>
                            <li><b>ID: </b>{currentOrder && currentOrder.id}</li>
                            <li><b>Customer id: </b>{currentOrder && currentOrder.customerId}</li>
                            <li><b>Deal id: </b>{currentOrder && currentOrder.dealID}</li>
                            <li><b>Product name: </b>{currentOrder && currentOrder.productName}</li>
                            <li><b>Product link: </b><a target="_blank" href={currentOrder && currentOrder.productLink}>{currentOrder && currentOrder.productLink}</a></li>
                            <li><b>Status: </b>{currentOrder && currentOrder.status}</li>
                            <li><b>Ảnh đơn hàng: </b><a target="_blank" href={currentOrder && currentOrder.billImg}>{currentOrder && currentOrder.billImg}</a><br/>
                                {currentOrder && currentOrder.billImg && <Box
                                    component="img"
                                    sx={{
                                        width: 100,
                                    }}
                                    alt=""
                                    src={currentOrder?.billImg}
                                />}
                            </li>
                            <li><b>Ảnh review: </b><a target="_blank" href={currentOrder && currentOrder.reviewImg}>{currentOrder && currentOrder.reviewImg}</a><br/>
                                {currentOrder && currentOrder.billImg && <Box
                                    component="img"
                                    sx={{
                                        width: 100,
                                    }}
                                    alt=""
                                    src={currentOrder?.reviewImg}
                                />}
                            </li>
                            <li><b>Ảnh hoàn tiền: </b><a target="_blank" href={currentOrder && currentOrder.refundImg}>{currentOrder && currentOrder.refundImg}</a></li>
                            <li><b>Thông tin hoàn tiền: </b>{currentOrder && currentOrder.paymentInfo}</li>
                            <li><b>Lý do từ chối: </b>{currentOrder && currentOrder.rejectedReason}</li>
                            <li><b>Tạo lúc: </b>{currentOrder && currentOrder.createdAt}</li>
                            <li><b>Cập nhật lần cuối: </b>{currentOrder && currentOrder.updatedAt}</li>
                        </ul>
                    </Dialog>
                    {orders && orders.map((order) => {
                        return (
                            <>
                                <TableRow>
                                    <TableCell>
                                        {order.id}
                                    </TableCell>
                                    <TableCell>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 50,
                                                width: 50,
                                            }}
                                            alt=""
                                            src={order.userImage}
                                        /><br/>
                                        {order.userPsid} - {order.userFbName}
                                    </TableCell>
                                    <TableCell>
                                        <Link href={order.shopLinkFacebook}>{order.shopName}</Link>
                                    </TableCell>
                                    <TableCell>
                                        {order.productName}
                                    </TableCell>
                                    <TableCell>
                                        {getStatusLabel(order.status)}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => {
                                            setCurrentOrder(order)
                                            setOpenDialog(true)
                                        }}>
                                            chi tiết
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                    })
                    }
                </TableBody>
            </Table>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePageSizeChange}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                />
            </Box>
        </TableContainer>
    )
};
export default ListOrdersTable;
