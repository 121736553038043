import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {FC, useEffect, useState} from "react";
import {Alert, Box, MenuItem, Snackbar} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Swap} from "../../../models/swap";
import {Voucher} from "../../../models/voucher";


interface ModalApproveSwapProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    refreshTable: () => void;
    swap: Swap;
}
const ModalApproveSwap: FC<ModalApproveSwapProps> = props => {
    const {open, setOpen, refreshTable, swap} = props;
    const [openNoti, setOpenNoti] = useState<boolean>(false);
    const [voucherSelected, setVoucherSelected] = useState<number>(0);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);

    const save = () => {
        if (voucherSelected != 0) {
            fetch(process.env.REACT_APP_DOMAIN + '/api/swap/approve', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': JSON.parse(localStorage.getItem("token")).token,
                },
                body: JSON.stringify({swapId: swap.id, voucherId: voucherSelected})
            }).then(response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else if (response.status == 200) {
                    setOpenNoti(true)
                    setOpen(false)
                    refreshTable()
                } else {
                    alert("Đã có lỗi xảy ra")
                }
            })
        }
    }
    useEffect(() => {
        if (open && swap != undefined) {
            getCurrentActiveVoucher(swap.swapValue)
        }

    }, [open, swap]);

    const getCurrentActiveVoucher = (voucherType: number) => {
        const headers = {'token': JSON.parse(localStorage.getItem("token")).token}
        fetch(`${process.env.REACT_APP_DOMAIN}/api/voucher/list?page=0&pageSize=10&status=0&type=${voucherType}`, {headers})
            .then(async response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    const data = await response.json();
                    setVouchers(data.vouchers)
                }
            })
    }

    return (
        <>
            <Snackbar open={openNoti} onClose={event => {
                setOpenNoti(false)
            }} autoHideDuration={6000}>
                <Alert severity="success" sx={{width: '100%'}}>
                   Duyệt yêu cầu thành công
                </Alert>
            </Snackbar>

            <Dialog open={open} onClose={event => {
                setOpen(false)
            }}>
                <DialogTitle>Duyệt yêu cầu đổi điểm số: {swap?.id}</DialogTitle>
                <div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root ': {m: 1, width: '25ch'},
                            '& .MuiButton-root': {m: 2, width: '15ch'},
                            mx: "auto"
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Chọn voucher"
                                value={voucherSelected}
                                onChange={event => setVoucherSelected(parseInt(event.target.value))}
                            >
                                {vouchers.map((statusOption) => (
                                    <MenuItem key={statusOption.id} value={statusOption.id}>
                                        {statusOption.voucherCode}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <Button fullWidth variant="contained" onClick={event => save()}>
                                Lưu
                            </Button>
                        </div>
                    </Box>
                </div>
            </Dialog>
        </>
    );
}
export default ModalApproveSwap;