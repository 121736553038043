import {ChangeEvent, FC, useEffect, useState} from 'react';
import moment from 'moment';
import {
    Box,
    Card,
    CardHeader,
    Divider,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';

import Label from 'src/components/Label';
import {Voucher} from "../../../models/voucher";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddVoucherDialog from "./ModalAddVoucher";

interface Filters {
    status?: number;
    type?: string;
}


const RecentOrdersTable: FC = () => {

    const [voucher, setVoucher] = useState<Array<Voucher>>([]);
    const [page, setPage] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [filters, setFilters] = useState<Filters>({
        status: -1,
        type: ""
    });

    const statusOptions = [
        {
            id: '-1',
            name: 'All'
        },
        {
            id: '1',
            name: 'Đã dùng'
        },
        {
            id: '0',
            name: 'Chưa dùng'
        }
    ];
    const typeOptions = [
        {
            id: ' ',
            name: 'All'
        },
        {
            id: '50000',
            name: '50000'
        },
        {
            id: '100000',
            name: '100000'
        },
        {
            id: '200000',
            name: '200000'
        },
        {
            id: '500000',
            name: '500000'
        }
    ];

    const handleTypeChange = (e: ChangeEvent<HTMLInputElement>): void => {
        let value = null;

        value = e.target.value;

        setFilters((prevFilters) => ({
            ...prevFilters,
            type: value
        }));
    };

    const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
        let value = null;

        if (e.target.value !== 'all') {
            value = e.target.value;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            status: value
        }));
    };
    const getVoucher = (page: number, limit: number, filters: Filters) => {
        const headers = {'token': JSON.parse(localStorage.getItem("token")).token}
        fetch(`${process.env.REACT_APP_DOMAIN}/api/voucher/list?page=${page}&pageSize=${limit}&status=${filters.status}&type=${filters.type}`, {headers})
            .then(async response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    const data = await response.json();
                    setVoucher(data.vouchers)
                    setTotal(data.count)
                }
            })
    }
    useEffect(() => {
        getVoucher(page, limit, filters);
    }, [filters, page, limit]);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };


    return (
        <Card>
            <AddVoucherDialog open={openModal} setOpen={setOpenModal} refreshTable={() => {
                getVoucher(page, limit, filters)
            }}/>
            <CardHeader
                action={
                    <div>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root ': {m: 1, width: '25ch'},
                                '& .MuiButton-root': {m: 2, width: '15ch'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Status"
                                    value={filters.status || '-1'}
                                    onChange={handleStatusChange}
                                >
                                    {statusOptions.map((statusOption) => (
                                        <MenuItem key={statusOption.id} value={statusOption.id}>
                                            {statusOption.name}
                                        </MenuItem>
                                    ))}
                                </TextField> <TextField
                                id="outlined-select-currency"
                                select
                                label="Type"
                                value={filters.type || ' '}
                                onChange={handleTypeChange}
                            >
                                {typeOptions.map((statusOption) => (
                                    <MenuItem key={statusOption.id} value={statusOption.id}>
                                        {statusOption.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                                <Button variant="contained" onClick={event => setOpenModal(true)}>
                                    Thêm mới
                                </Button>
                            </div>
                        </Box>
                    </div>
                }
                title="Quản lý Voucher"
            />
            <Divider/>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nền tảng</TableCell>
                            <TableCell>Voucher Code</TableCell>
                            <TableCell>Trị giá</TableCell>
                            <TableCell>Thời gian tạo</TableCell>
                            <TableCell>Người tạo</TableCell>
                            <TableCell>Đã cấp cho</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {voucher.map((cryptoOrder) => {

                            return (
                                <TableRow
                                    hover
                                    key={cryptoOrder.id}
                                >
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.id}
                                        </Typography>
                                        {/*<Typography variant="body2" color="text.secondary" noWrap>*/}
                                        {/*    {format(cryptoOrder.orderDate, 'MMMM dd yyyy')}*/}
                                        {/*</Typography>*/}
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.platform}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.voucherCode}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.voucherValue}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {moment(cryptoOrder.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.createdBy}
                                        </Typography>

                                    </TableCell>

                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.userID}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="center">
                                        <Label
                                            color={cryptoOrder.used == 1 ? "error" : "success"}>{cryptoOrder.used == 1 ? "Đã dùng" : "Chưa dùng"}</Label>

                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                />
            </Box>
        </Card>
    );
};
export default RecentOrdersTable;
