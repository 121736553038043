import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {ChangeEvent, FC, useEffect, useState} from "react";
import {Swap} from "../../../models/swap";
import {UserLog} from "../../../models/user_log";
import {
    Box,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";


interface ModalUserInfoProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    swap: Swap;
}

const ModalUserInfo: FC<ModalUserInfoProps> = props => {
    const {open, setOpen, swap} = props;
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [logs, setLogs] = useState<UserLog[]>([]);
    const [coin, setCoin] = useState<number>(0);
    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };
    useEffect(() => {
        if (open && swap != undefined) {
            getUserLog()
        }

    }, [open, swap, page, limit]);

    const getUserLog = () => {
        const headers = {'token': JSON.parse(localStorage.getItem("token")).token}
        fetch(`${process.env.REACT_APP_DOMAIN}/api/user/logCoin?page=${page}&pageSize=${limit}&userId=${swap.userId}`, {headers})
            .then(async response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    const data = await response.json();
                    setLogs(data.logs)
                    setTotal(data.count)
                    setCoin(data.coin)
                }
            })
    }

    return (
        <Dialog open={open} fullWidth maxWidth={"lg"} onClose={event => {
            setOpen(false)
        }}>
            <DialogTitle>Thông tin người dùng: {swap?.userId}. Điểm đang có: {coin}</DialogTitle>
            <Divider/>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Thời gian</TableCell>
                            <TableCell>Giá trị thay đổi</TableCell>
                            <TableCell>Lý do</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs.map((cryptoOrder) => {

                            // @ts-ignore
                            // @ts-ignore
                            return (
                                <TableRow
                                    hover
                                    key={cryptoOrder.id}
                                >
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.id}
                                        </Typography>
                                        {/*<Typography variant="body2" color="text.secondary" noWrap>*/}
                                        {/*    {format(cryptoOrder.orderDate, 'MMMM dd yyyy')}*/}
                                        {/*</Typography>*/}
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.createdAt}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.coinChange}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.reason}
                                        </Typography>

                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                />
            </Box>
        </Dialog>
    );
}
export default ModalUserInfo;