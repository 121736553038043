import {FC, useEffect, useState} from 'react';
import {
    Card,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import ChangeConfig from "./ModalChangeConfig";
import {Config} from "../../../models/voucher";
import Button from "@mui/material/Button";


const RecentOrdersTable: FC = () => {

    const [voucher, setVoucher] = useState<Array<Config>>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [currentEdit, setCurrentEdit] = useState<Config>();

    const getVoucher = () => {
        const headers = {'token': JSON.parse(localStorage.getItem("token")).token}
        fetch(`${process.env.REACT_APP_DOMAIN}/api/config/list`, {headers})
            .then(async response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    const data = await response.json();
                    setVoucher(data)
                }
            })
    }
    useEffect(() => {
        getVoucher();
    }, []);

    return (
        <Card>
            { currentEdit && <ChangeConfig currentEdit={currentEdit} open={openModal} setOpen={setOpenModal} refreshTable={() => {
                getVoucher()
            }}/> }
            <CardHeader
                title="Quản lý cấu hình"
            />
            <Divider/>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>#</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {voucher.map((cryptoOrder) => {

                            return (
                                <TableRow
                                    hover
                                    key={cryptoOrder.code}
                                >
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.code}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.value.substring(0, 50)}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="right">
                                        <Button color="success" size="small"
                                                variant="outlined"
                                                onClick={event => {
                                                    setOpenModal(true);
                                                    setCurrentEdit(cryptoOrder)
                                                }}>
                                            Sửa
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};
export default RecentOrdersTable;
