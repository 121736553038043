import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {FC, useEffect, useState} from "react";
import {Alert, Box, MenuItem, Snackbar} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Swap} from "../../../models/swap";
import {Voucher} from "../../../models/voucher";


interface ModalRejectSwapProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    refreshTable: () => void;
    swap: Swap;
}

const ModalRejectSwap: FC<ModalRejectSwapProps> = props => {
    const {open, setOpen, refreshTable, swap} = props;
    const [openNoti, setOpenNoti] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");

    const save = () => {
        if (reason != "") {
            fetch(process.env.REACT_APP_DOMAIN + '/api/swap/reject', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': JSON.parse(localStorage.getItem("token")).token,
                },
                body: JSON.stringify({swapId: swap.id, reason: reason})
            }).then(response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else if (response.status == 200) {
                    setOpenNoti(true)
                    setOpen(false)
                    refreshTable()
                } else {
                    alert("Đã có lỗi xảy ra")
                }
            })
        }
    }

    return (
        <>
            <Snackbar open={openNoti} onClose={event => {
                setOpenNoti(false)
            }} autoHideDuration={6000}>
                <Alert severity="success" sx={{width: '100%'}}>
                   Từ chối yêu cầu thành công
                </Alert>
            </Snackbar>

            <Dialog open={open} onClose={event => {
                setOpen(false)
            }}>
                <DialogTitle>Từ chối yêu cầu đổi điểm số: {swap?.id}</DialogTitle>
                <div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root ': {m: 1, width: '25ch'},
                            '& .MuiButton-root': {m: 2, width: '15ch'},
                            mx: "auto"
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-select-currency"
                                multiline
                                rows={4}
                                label="Nhập lý do"
                                value={reason}
                                onChange={event => setReason(event.target.value)}
                            >
                            </TextField>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <Button fullWidth variant="contained" onClick={event => save()}>
                                Lưu
                            </Button>
                        </div>
                    </Box>
                </div>
            </Dialog>
        </>
    );
}
export default ModalRejectSwap;