import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
    Box,
    Button, IconButton, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    MenuItem, SelectChangeEvent, FormControl, InputLabel
} from '@mui/material';

import Label from 'src/components/Label';
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';
import DealV2 from "../../../models/deal";

const getStatusLabel = (status: string): JSX.Element => {
    if (status === 'DRAFT') {
        return <Label color={"warning"}>Chờ duyệt</Label>;
    }
    if (status === 'ACTIVE') {
        return <Label color={"success"}>Đang hoạt động</Label>;
    }
    if (status === 'REJECTED') {
        return <Label color={"error"}>Đã từ chối</Label>;
    }
    if (status === 'FINISHED') {
        return <Label color={"secondary"}>Đã hết hạn</Label>;
    }
    return <Label></Label>
}

const ListDealsTable: FC = () => {
    const [deals, setDeals] = useState<Array<DealV2>>([]);
    const [currentWatchingDeal, setCurrentWatchingDeal] = useState<DealV2>(null);
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [status, setStatus] = useState<string>("");
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [reloadGettingDeals, setReloadGettingDeals] = useState<number>(0)
    const [rejectedReason, setRejectedReason] = useState<string>("")
    const [openDialog1, setOpenDialog1] = useState<boolean>(false)

    const getDeals = (page: number, pageSize: number, status: string) => {
        const headers = { 'token': JSON.parse(localStorage.getItem("token")).token }
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/deals?page=${page}&pageSize=${pageSize}&status=${status}`, { headers })
            .then(async response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    const data = await response.json();
                    if (data) {
                        setDeals(data.data.deals)
                        setTotal(data.data.total)
                    } else {
                        setDeals([])
                    }
                }
            })
    }

    useEffect(() => {
        getDeals(page, pageSize, status);
    }, [status, page, pageSize, reloadGettingDeals]);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setPageSize(parseInt(event.target.value));
    };

    const handleStatusChange = (event: SelectChangeEvent): void => {
        setStatus(event.target.value as string)
    };

    const handleApproveDeal = (dealId: number): void => {
        const headers = { 'token': JSON.parse(localStorage.getItem("token")).token }
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/deals/${dealId}/approve`, { headers })
            .then(async response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    setReloadGettingDeals(reloadGettingDeals + 1)
                }
            })
    }

    const handleRejectDeal = (dealId: number, reason: string): void => {
        const headers = { 'token': JSON.parse(localStorage.getItem("token")).token }
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/deals/${dealId}/reject?reason=${reason}`, { headers })
            .then(async response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    setReloadGettingDeals(reloadGettingDeals + 1)
                }
            })
    }

    const handleStopDeal = (dealId: number): void => {
        const headers = { 'token': JSON.parse(localStorage.getItem("token")).token }
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/deals/${dealId}/stop`, { headers })
            .then(async response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    setReloadGettingDeals(reloadGettingDeals + 1)
                }
            })
    }

    return (
        <TableContainer style={{ padding: 20 }}>
            <FormControl>
                <InputLabel id="demo-simple-select-label">Trạng thái</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Age"
                    onChange={handleStatusChange}
                >
                    <MenuItem value={""}>Tất cả</MenuItem>
                    <MenuItem value={"DRAFT"}>Chờ duyệt</MenuItem>
                    <MenuItem value={"ACTIVE"}>Đang hoạt động</MenuItem>
                    <MenuItem value={"REJECTED"}>Đã từ chối</MenuItem>
                    <MenuItem value={"FINISHED"}>Đã dừng</MenuItem>
                </Select>
            </FormControl>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Tên sp</TableCell>
                        <TableCell>Trạng thái</TableCell>
                        <TableCell>Cập nhật bởi</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Dialog open={openDialog}>
                        <IconButton onClick={() => {
                            setOpenDialog(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                        <ul style={{ padding: 10 }}>
                            <li><b>ID: </b>{currentWatchingDeal && currentWatchingDeal.id}</li>
                            <li><b>Type: </b>{currentWatchingDeal && currentWatchingDeal.type}</li>
                            <li><b>Product name: </b>{currentWatchingDeal && currentWatchingDeal.productName}</li>
                            <li><b>Product link: </b><a target="_blank" href={currentWatchingDeal && currentWatchingDeal.productLink}>{currentWatchingDeal && currentWatchingDeal.productLink}</a></li>
                            <li><b>Product link: </b>{currentWatchingDeal && currentWatchingDeal.voucherCode}</li>
                            <li><b>Price: </b>{currentWatchingDeal && currentWatchingDeal.price}</li>
                            <li><b>Discount amount: </b>{currentWatchingDeal && currentWatchingDeal.discountAmount}</li>
                            <li><b>Max slots: </b>{currentWatchingDeal && currentWatchingDeal.maxSlots}</li>
                            <li><b>Used slots: </b>{currentWatchingDeal && currentWatchingDeal.usedSlots}</li>
                            <li><b>Payment method: </b>{currentWatchingDeal && currentWatchingDeal.paymentMethod}</li>
                            <li><b>Description: </b>{currentWatchingDeal && currentWatchingDeal.description}</li>
                            <li><b>Shop phone: </b>{currentWatchingDeal && currentWatchingDeal.shopPhone}</li>
                            <li><b>Shop fb: </b><a target="_blank" href={currentWatchingDeal && currentWatchingDeal.shopFB}>{currentWatchingDeal && currentWatchingDeal.shopFB}</a></li>
                            <li><b>Status: </b>{currentWatchingDeal && currentWatchingDeal.status}</li>
                            <li><b>Expired at: </b>{currentWatchingDeal && currentWatchingDeal.expiredAt}</li>
                            <li><b>Created at: </b>{currentWatchingDeal && currentWatchingDeal.createdAt}</li>
                            <li><b>Updated at: </b>{currentWatchingDeal && currentWatchingDeal.updatedAt}</li>
                            <li><b>Updated by: </b>{currentWatchingDeal && currentWatchingDeal.updatedBy}</li>
                        </ul>
                    </Dialog>
                    <Dialog open={openDialog1} style={{ padding: 10 }}>
                        <IconButton onClick={() => {
                            setOpenDialog1(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                        <input
                            id={"rejectedReason"}
                            type={"text"}
                            placeholder={"lý do từ chối deal"}
                            onChange={
                                (event) => {
                                    setRejectedReason(event.target.value)
                                }
                            } />
                        <Button onClick={
                            () => {
                                handleRejectDeal(currentWatchingDeal.id, rejectedReason)
                                setOpenDialog1(false)
                            }
                        }> Gửi </Button>
                    </Dialog>
                    {deals && deals.map((deal) => {
                        return (
                            <>
                                <TableRow>
                                    <TableCell>
                                        {deal.id}
                                    </TableCell>
                                    <TableCell>
                                        {deal.productName}
                                    </TableCell>
                                    <TableCell>
                                        {getStatusLabel(deal.status)}
                                    </TableCell>
                                    <TableCell>
                                        {deal.updatedBy}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => {
                                            setCurrentWatchingDeal(deal)
                                            setOpenDialog(true)
                                        }}>
                                            chi tiết
                                        </Button>
                                        <Button disabled={!(deal.status === 'DRAFT')} onClick={
                                            () => {
                                                handleApproveDeal(deal.id)
                                            }
                                        }>
                                            duyệt
                                        </Button>
                                        <Button disabled={!(deal.status === 'DRAFT')}
                                            onClick={() => {
                                                setCurrentWatchingDeal(deal)
                                                setOpenDialog1(true)
                                            }}>
                                            từ chối duyệt
                                        </Button>
                                        <Button disabled={!(deal.status === 'ACTIVE')}
                                                onClick={() => {
                                                    handleStopDeal(deal.id)
                                                }}>
                                            dừng
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                    })
                    }
                </TableBody>
            </Table>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePageSizeChange}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                />
            </Box>
        </TableContainer>
    )
};
export default ListDealsTable;
