import { Card } from '@mui/material';
import ListShopsTable from "./ListShopsTable";

function ListShops() {
    return (
        <Card>
            <ListShopsTable />
        </Card>
    );
}

export default ListShops;
