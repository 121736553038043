import {Helmet} from 'react-helmet-async';
import {Container, Grid} from '@mui/material';
import Footer from 'src/components/Footer';

import RecentOrders from './RecentOrders';

function CoinManagement() {
    return (
        <>
            <Helmet>
                <title>Quản lý người dùng</title>
            </Helmet>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <RecentOrders/>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default CoinManagement;
