import {Helmet} from "react-helmet-async";
import {Container, Grid} from "@mui/material";
import Footer from "../../../components/Footer";
import ListDeals from "./ListDeals";

function DealManagement() {
    return (
        <> <br/>
            <Helmet>
                <title>Quản lý deal</title>
            </Helmet>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        {<ListDeals />}
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
}

export default DealManagement;
