import { Card } from '@mui/material';
import ListOrdersTable from "./ListOrdersTable";

function ListOrders() {
    return (
        <Card>
            <ListOrdersTable />
        </Card>
    );
}

export default ListOrders;
