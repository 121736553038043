import { Card } from '@mui/material';
import ListDealsTable from "./ListDealsTable";

function ListDeals() {
    return (
        <Card>
            <ListDealsTable />
        </Card>
    );
}

export default ListDeals;
