import {ChangeEvent, FC, useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardHeader,
    Divider,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';

import Label from 'src/components/Label';
import TextField from "@mui/material/TextField";
import {Swap} from "../../../models/swap";
import Button from "@mui/material/Button";
import ModalApproveSwap from "./ModalApproveSwap";
import ModalRejectSwap from "./ModalRejectSwap";
import ModalUserInfo from "./ModalUserInfo";
import moment from "moment";

interface Filters {
    status?: number;
}

const getStatusLabel = (status: number): JSX.Element => {
    if (status == 0) {
        return <Label color={"warning"}>Đợi duyệt</Label>;
    } else if (status == 1) {
        return <Label color={"success"}>Đã duyệt</Label>;
    } else {
        return <Label color={"error"}>Đã từ chối</Label>;
    }
}

const RecentOrdersTable: FC = () => {
    const [currentSwap, setCurrentSwap] = useState<Swap>();
    const [voucher, setVoucher] = useState<Array<Swap>>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openModalUser, setOpenModalUser] = useState<boolean>(false);
    const [openModalReject, setOpenModalReject] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [filters, setFilters] = useState<Filters>({
        status: -1,
    });

    const statusOptions = [
        {
            id: '-1',
            name: 'All'
        },
        {
            id: '0',
            name: 'Đợi duyệt'
        },
        {
            id: '1',
            name: 'Đã duyệt'
        },
        {
            id: '2',
            name: 'Đã từ chối'
        }
    ];

    const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
        let value = null;

        if (e.target.value !== 'all') {
            value = e.target.value;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            status: value
        }));
    };

    const getVoucher = (page: number, limit: number, filters: Filters) => {
        const headers = {'token': JSON.parse(localStorage.getItem("token")).token}
        fetch(`${process.env.REACT_APP_DOMAIN}/api/swap/list?page=${page}&pageSize=${limit}&status=${filters.status}`, {headers})
            .then(async response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    const data = await response.json();
                    setVoucher(data.swaps)
                    setTotal(data.count)
                }
            })
    }
    useEffect(() => {
        getVoucher(page, limit, filters);
    }, [filters, page, limit]);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };


    return (
        <Card>
            <ModalUserInfo open={openModalUser} setOpen={setOpenModalUser} swap={currentSwap}/>
            <ModalApproveSwap open={openModal} setOpen={setOpenModal} refreshTable={() => {
                getVoucher(page, limit, filters)
            }} swap={currentSwap}/>
            <ModalRejectSwap open={openModalReject} setOpen={setOpenModalReject} refreshTable={() => {
                getVoucher(page, limit, filters)
            }} swap={currentSwap}/>
            <CardHeader
                action={
                    <div>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root ': {m: 1, width: '25ch'},
                                '& .MuiButton-root': {m: 2, width: '15ch'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Status"
                                    value={filters.status || '-1'}
                                    onChange={handleStatusChange}
                                >
                                    {statusOptions.map((statusOption) => (
                                        <MenuItem key={statusOption.id} value={statusOption.id}>
                                            {statusOption.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Box>
                    </div>
                }
                title="Quản lý yêu cầu đổi điểm"
            />
            <Divider/>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>Trị giá</TableCell>
                            <TableCell>Voucher Code</TableCell>
                            <TableCell>Người xử lý</TableCell>
                            <TableCell>Thời gian yêu cầu - xử lý</TableCell>
                            <TableCell>Trace Key</TableCell>
                            <TableCell>Lý do từ chối</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">#</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {voucher.map((cryptoOrder) => {

                            // @ts-ignore
                            // @ts-ignore
                            return (
                                <TableRow
                                    hover
                                    key={cryptoOrder.id}
                                >
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.id}
                                        </Typography>
                                        {/*<Typography variant="body2" color="text.secondary" noWrap>*/}
                                        {/*    {format(cryptoOrder.orderDate, 'MMMM dd yyyy')}*/}
                                        {/*</Typography>*/}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={event => {
                                            setOpenModalUser(true);
                                            setCurrentSwap(cryptoOrder)
                                        }}>
                                            {cryptoOrder.userId}
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.swapValue + " - " + cryptoOrder.paymentMethod + ' - ' + cryptoOrder.phone}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.voucherCode}
                                        </Typography>

                                    </TableCell>

                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.userProcess}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom

                                        >
                                            {moment(cryptoOrder.createdAt).format('DD/MM/YYYY HH:mm:ss') + ' - ' + moment(cryptoOrder.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.traceKey}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.rejectReason}
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="center">
                                        {getStatusLabel(cryptoOrder.status)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {cryptoOrder.status == 0 &&
                                        <Stack direction="row" spacing={2}><Button color="success" size="small"
                                                                                   variant="outlined"
                                                                                   onClick={event => {
                                                                                       setOpenModal(true);
                                                                                       setCurrentSwap(cryptoOrder)
                                                                                   }}>
                                            Duyệt
                                        </Button>
                                            <Button variant="outlined" size="small" color="error"
                                                    onClick={event => {
                                                        setOpenModalReject(true);
                                                        setCurrentSwap(cryptoOrder)
                                                    }}>
                                                Từ chối
                                            </Button></Stack>}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                />
            </Box>
        </Card>
    )
};
export default RecentOrdersTable;
