import {Card} from '@mui/material';
import RecentOrdersTable from './RecentOrdersTable';

function RecentOrders() {
    return (
        <Card>
            <RecentOrdersTable/>
        </Card>
    );
}

export default RecentOrders;
