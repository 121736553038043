import {Helmet} from "react-helmet-async";
import {Container, Grid} from "@mui/material";
import Footer from "../../../components/Footer";
import ListOrders from "./ListOrders";

function OrderManagement() {
    return (
        <> <br/>
            <Helmet>
                <title>Quản lý đơn hàng</title>
            </Helmet>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        {<ListOrders />}
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
}

export default OrderManagement;
