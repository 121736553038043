import {ChangeEvent, FC, useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardHeader,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ModalUserInfo from "./ModalUserInfo";
import {User} from "../../../models/user";
import ModalEditCoinUser from "./ModalEditCoinUser";
import {Message} from "../../../models/message";
import moment from "moment";

interface Filters {
    status?: number;
    keyword?: string;
    psid?: string;
}

const RecentOrdersTable: FC = () => {
    const [currentUser, setCurrentUser] = useState<Message>();
    const [users, setUsers] = useState<Array<Message>>([]);
    const [openModalUser, setOpenModalUser] = useState<boolean>(false);
    const [openModalUserEdit, setOpenModalUserEdit] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [filters, setFilters] = useState<Filters>({
        status: -1,
        keyword: "",
        psid: "",
    });

    const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            keyword: e.target.value
        }));
    };

    const handlePsidChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            psid: e.target.value
        }));
    };

    const getUser = (page: number, limit: number, filters: Filters) => {
        const headers = {'token': JSON.parse(localStorage.getItem("token")).token}
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/messages?page=${page}&pageSize=${limit}&keyword=${filters.keyword}&psid=${filters.psid}`, {headers})
            .then(async response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    const data = await response.json();
                    setUsers(data.data.messages)
                    setTotal(data.data.total)
                }
            })
    }
    useEffect(() => {
        getUser(page, limit, filters);
    }, [filters, page, limit]);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };


    return (
        <Card>
            {/*<ModalUserInfo open={openModalUser} setOpen={setOpenModalUser} user={currentUser}/>*/}
            {/*<ModalEditCoinUser open={openModalUserEdit} setOpen={setOpenModalUserEdit} refreshTable={() => {*/}
            {/*    getUser(page, limit, filters)*/}
            {/*}} user={currentUser}/>*/}
            <CardHeader
                action={
                    <div>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root ': {m: 1, width: '25ch'},
                                '& .MuiButton-root': {m: 2, width: '15ch'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    id="outlined-select-currency"
                                    label="User ID"
                                    value={filters.psid}

                                    onChange={handlePsidChange}
                                >

                                </TextField>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Tin nhắn"
                                    value={filters.keyword}

                                    onChange={handleStatusChange}
                                >

                                </TextField>
                            </div>
                        </Box>
                    </div>
                }
                title="Quản lý người dùng"
            />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Tên FB</TableCell>
                            <TableCell>Tin nhắn</TableCell>
                            <TableCell>Thời gian gửi</TableCell>
                            <TableCell align="center">#</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((cryptoOrder) => {

                            // @ts-ignore
                            // @ts-ignore
                            return (
                                <TableRow
                                    hover
                                    key={cryptoOrder.ID}

                                >
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.ID}
                                        </Typography>
                                        {/*<Typography variant="body2" color="text.secondary" noWrap>*/}
                                        {/*    {format(cryptoOrder.orderDate, 'MMMM dd yyyy')}*/}
                                        {/*</Typography>*/}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 50,
                                                width: 50,
                                            }}
                                            alt=""
                                            src={cryptoOrder.Image}
                                        /> <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {cryptoOrder.UserID + ' - '+ cryptoOrder.FbName}
                                        </Typography>

                                    </TableCell>
                                    <TableCell  style={{
                                        whiteSpace: "normal",
                                        wordWrap: "break-word"
                                    }}>

                                            {cryptoOrder.Message}

                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {moment(cryptoOrder.CreatedAt).format('DD/MM/YYYY HH:mm:ss')}
                                        </Typography>

                                    </TableCell>


                                    <TableCell align="right">

                                        <Stack direction="row" spacing={2}><Button color="success" size="small"
                                                                                   variant="outlined"
                                                                                   onClick={event => {
                                                                                       setOpenModalUserEdit(true);
                                                                                       setCurrentUser(cryptoOrder);
                                                                                   }}>
                                           Gửi tin
                                        </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                />
            </Box>
        </Card>
    )
};
export default RecentOrdersTable;
