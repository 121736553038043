import {useRoutes} from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import {CssBaseline} from '@mui/material';
import React from 'react';
import Login from "./components/Login/Login";
import useToken from "./components/Login/tokenhook";

const App = () => {

    const content = useRoutes(routes);
    const {token, setToken} = useToken();
    if (!token) {
        return <Login setToken={setToken}/>
    }
    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline/>
                {content}
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;
