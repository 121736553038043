import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {FC, useState} from "react";
import {Alert, Box, MenuItem, Snackbar} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {User} from "../../../models/user";


interface ModalEditCoinUserProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    refreshTable: () => void;
    user: User;
}

const typeOptions = [
    {
        id: '0',
        name: 'Trừ'
    },
    {
        id: '1',
        name: 'Cộng'
    }
];

const ModalEditCoinUser: FC<ModalEditCoinUserProps> = props => {
    const {open, setOpen, refreshTable, user} = props;
    const [openNoti, setOpenNoti] = useState<boolean>(false);
    const [typeChange, setTypeChange] = useState<number>(0);
    const [changeValue, setChangeValue] = useState<number>(0);
    const [reason, setReason] = useState<string>("");

    const save = () => {
        if (changeValue != 0 && reason != "") {
            fetch(process.env.REACT_APP_DOMAIN + '/api/user/coinChange', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': JSON.parse(localStorage.getItem("token")).token,
                },
                body: JSON.stringify({
                    userId: user.userId,
                    changeType: typeChange,
                    changeValue: changeValue,
                    reason: reason
                })
            }).then(response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else if (response.status == 200) {
                    setOpenNoti(true)
                    setOpen(false)
                    refreshTable()
                } else {
                    alert("Đã có lỗi xảy ra")
                }
            })
        }
    }

    return (
        <>
            <Snackbar open={openNoti} onClose={event => {
                setOpenNoti(false)
            }} autoHideDuration={6000}>
                <Alert severity="success" sx={{width: '100%'}}>
                    Đổi điểm thành công
                </Alert>
            </Snackbar>

            <Dialog maxWidth={"md"} open={open} onClose={event => {
                setOpen(false)
            }}>
                <DialogTitle>Sửa điểm của người dùng: {user?.userId}</DialogTitle>
                <div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root ': {m: 1, width: '50ch'},
                            '& .MuiButton-root': {m: 2, width: '15ch'},
                            mx: "auto"
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField sx={{m: 2}}
                                       id="outlined-select-currency"
                                       select
                                       label="Loại thay đổi"
                                       value={typeChange}
                                       onChange={event => setTypeChange(parseInt(event.target.value))}
                            >
                                {typeOptions.map((statusOption) => (
                                    <MenuItem key={statusOption.id} value={statusOption.id}>
                                        {statusOption.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div>
                            <TextField
                                label="Số điểm thay đổi"
                                value={changeValue}
                                onChange={event => {
                                    if (event.target.value != undefined && event.target.value != "") {
                                        setChangeValue(parseInt(event.target.value))
                                    } else {
                                        setChangeValue(0)
                                    }
                                }}
                            >
                            </TextField>
                        </div>

                        <div>
                            <TextField
                                id="outlined-select-currency"
                                multiline
                                rows={4}
                                label="Nhập lý do"
                                value={reason}
                                onChange={event => setReason(event.target.value)}
                            >
                            </TextField>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <Button fullWidth variant="contained" onClick={event => save()}>
                                Lưu
                            </Button>
                        </div>
                    </Box>
                </div>
            </Dialog>
        </>
    );
}
export default ModalEditCoinUser;