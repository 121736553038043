import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {FC, useState} from "react";
import {Alert, Box, MenuItem, Snackbar} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";


interface AddVoucherDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    refreshTable: () => void;
}

const typeOptions = [
    {
        id: '50000',
        name: '50000'
    },
    {
        id: '100000',
        name: '100000'
    },
    {
        id: '200000',
        name: '200000'
    },
    {
        id: '500000',
        name: '500000'
    }
];
export const REGEX = {
    FIELD_ASCII_AND_NUMBER: /^[a-zA-Z0-9]+$/
}
const AddVoucherDialog: FC<AddVoucherDialogProps> = props => {
    const {open, setOpen, refreshTable} = props;
    const [voucher, setVoucher] = useState<string>("");
    const [openNoti, setOpenNoti] = useState<boolean>(false);
    const [voucherError, setVoucherError] = useState<boolean>(false);
    const [voucherType, setVoucherType] = useState<string>("50000");

    const save = () => {
        if (REGEX.FIELD_ASCII_AND_NUMBER.test(voucher)) {
            setVoucherError(false)
            fetch(process.env.REACT_APP_DOMAIN + '/api/voucher/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': JSON.parse(localStorage.getItem("token")).token,
                },
                body: JSON.stringify({code: voucher, value: voucherType})
            }).then(response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else if (response.status == 200) {
                    setOpenNoti(true)
                    setOpen(false)
                    refreshTable()
                } else {
                    alert("Đã có lỗi xảy ra")
                }
            })
        } else {
            setVoucherError(true)
        }
    }

    return (
        <>
            <Snackbar open={openNoti} onClose={event => {
                setOpenNoti(false)
            }} autoHideDuration={6000}>
                <Alert severity="success" sx={{width: '100%'}}>
                    Lưu voucher thành công
                </Alert>
            </Snackbar>
            <Dialog open={open} onClose={event => {
                setOpen(false)
            }}>
                <DialogTitle>Thêm mới Voucher</DialogTitle>
                <div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root ': {m: 1, width: '25ch'},
                            '& .MuiButton-root': {m: 2, width: '15ch'},
                            mx: "auto"
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Type"
                                value={voucherType}
                                onChange={event => setVoucherType(event.target.value)}
                            >
                                {typeOptions.map((statusOption) => (
                                    <MenuItem key={statusOption.id} value={statusOption.id}>
                                        {statusOption.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div>
                            <TextField
                                label="Voucher code"
                                value={voucher}
                                error={voucherError}
                                onChange={event => setVoucher(event.target.value)}
                            >
                            </TextField>

                        </div>
                        <div style={{textAlign: "center"}}>
                            <Button fullWidth variant="contained" onClick={event => save()}>
                                Lưu
                            </Button>
                        </div>
                    </Box>
                </div>
            </Dialog>
        </>
    );
}
export default AddVoucherDialog