import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {FC, useEffect, useState} from "react";
import {Alert, Box, Snackbar, TextareaAutosize} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Config} from "../../../models/voucher";


interface ChangeConfigProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    refreshTable: () => void;
    currentEdit: Config;
}

export const REGEX = {
    FIELD_ASCII_AND_NUMBER: /^[a-zA-Z0-9]+$/
}
const ChangeConfig: FC<ChangeConfigProps> = props => {
    const {open, setOpen, refreshTable, currentEdit} = props;
    console.log(currentEdit)
    const [voucher, setVoucher] = useState<string>(currentEdit.value);
    const [openNoti, setOpenNoti] = useState<boolean>(false);
    const [configKey, setConfigKey] = useState<string>(currentEdit.code);

    const save = () => {
            fetch(process.env.REACT_APP_DOMAIN + '/api/config/list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': JSON.parse(localStorage.getItem("token")).token,
                },
                body: JSON.stringify({code: configKey, value: voucher})
            }).then(response => {
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else if (response.status == 200) {
                    setOpenNoti(true)
                    setOpen(false)
                    refreshTable()
                } else {
                    alert("Đã có lỗi xảy ra")
                }
            })
    }
    useEffect(() => {
        setVoucher(currentEdit.value)
        setConfigKey(currentEdit.code)
    }, [currentEdit]);

    return (
        <>
            <Snackbar open={openNoti} onClose={event => {
                setOpenNoti(false)
            }} autoHideDuration={6000}>
                <Alert severity="success" sx={{width: '100%'}}>
                    Lưu config thành công
                </Alert>
            </Snackbar>
            <Dialog fullWidth maxWidth={"lg"} open={open} onClose={event => {
                setOpen(false)
            }}>
                <DialogTitle>Chỉnh sửa config</DialogTitle>
                <div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root ': {m: 1, width: '25ch'},
                            '& .MuiButton-root': {m: 2, width: '15ch'},
                            mx: "auto"
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-select-currency"
                                label="Type"
                                disabled={true}
                                value={configKey}
                                onChange={event => setConfigKey(event.target.value)}
                            >
                            </TextField>
                        </div>
                        <div>
                            {/*<TextareaAutosize*/}
                            {/*    label="Voucher code"*/}
                            {/*    value={voucher}*/}
                            {/*    error={voucherError}*/}
                            {/*    onChange={event => setVoucher(event.target.value)}*/}
                            {/*>*/}
                            {/*</TextareaAutosize>*/}
                            <TextareaAutosize
                                maxRows={20}
                                minRows={10}
                                aria-label="maximum height"
                                placeholder="Maximum 4 rows"
                                defaultValue={voucher}
                                style={{ width: "100%", margin: "5px" }}
                                onChange={event => setVoucher(event.target.value)}
                            />
                        </div>
                        <div style={{textAlign: "center"}}>
                            <Button fullWidth variant="contained" onClick={event => save()}>
                                Lưu
                            </Button>
                        </div>
                    </Box>
                </div>
            </Dialog>
        </>
    );
}
export default ChangeConfig