import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
    Box,
    Button, IconButton, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    MenuItem, SelectChangeEvent, FormControl, InputLabel
} from '@mui/material';

import Label from 'src/components/Label';
import Shop from "../../../models/shop";
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';

const getStatusLabel = (status: string): JSX.Element => {
    if (status === 'INACTIVE') {
        return <Label color={"warning"}>Chờ duyệt</Label>;
    }
    if (status === 'ACTIVE') {
        return <Label color={"success"}>Đang hoạt động</Label>;
    }
    if (status === 'REJECTED') {
        return <Label color={"secondary"}>Đã từ chối</Label>;
    }
    return <Label color={"error"}>Đã khóa</Label>;
}

const ListShopsTable: FC = () => {
    const [shops, setShops] = useState<Array<Shop>>([]);
    const [currentShop, setCurrentShop] = useState<Shop>();
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [status, setStatus] = useState<string>("");
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [reloadGettingShops, setReloadGettingShops] = useState<number>(0)

    const getShops = (page: number, pageSize: number, status: string) => {
        const headers = { 'token': JSON.parse(localStorage.getItem("token")).token }
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/shops?page=${page}&pageSize=${pageSize}&status=${status}`, { headers })
            .then(async response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    const data = await response.json();
                    if (data) {
                        setShops(data.data.shops)
                        setTotal(data.data.total)
                    } else {
                        setShops([])
                    }
                }
            })
    }

    useEffect(() => {
        console.log(status, pageSize, page);
        getShops(page, pageSize, status);
    }, [status, page, pageSize, reloadGettingShops]);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setPageSize(parseInt(event.target.value));
    };

    const handleStatusChange = (event: SelectChangeEvent): void => {
        setStatus(event.target.value as string)
    };

    const handleApproveShop = (shopID: number): void => {
        const headers = { 'token': JSON.parse(localStorage.getItem("token")).token }
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/shops/${shopID}/approve`, { headers })
            .then(async response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    setReloadGettingShops(reloadGettingShops + 1)
                }
            })
    }


    const handleLockShop = (shopID: number): void => {
        const headers = { 'token': JSON.parse(localStorage.getItem("token")).token }
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/shops/${shopID}/lock`, { headers })
            .then(async response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    setReloadGettingShops(reloadGettingShops + 1)
                }
            })
    }

    const handleRejectShop = (shopID: number): void => {
        const headers = { 'token': JSON.parse(localStorage.getItem("token")).token }
        fetch(`${process.env.REACT_APP_DOMAIN}/api/admin/shops/${shopID}/reject`, { headers })
            .then(async response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    window.location.href = '/';
                } else {
                    setReloadGettingShops(reloadGettingShops + 1)
                }
            })
    }

    return (
        <TableContainer style={{ padding: 20 }}>
            <FormControl>
                <InputLabel id="demo-simple-select-label">Trạng thái</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Age"
                    onChange={handleStatusChange}
                >
                    <MenuItem value={""}>Tất cả</MenuItem>
                    <MenuItem value={"INACTIVE"}>Chờ duyệt</MenuItem>
                    <MenuItem value={"ACTIVE"}>Đang hoạt động</MenuItem>
                    <MenuItem value={"REJECTED"}>Đã từ chối</MenuItem>
                    <MenuItem value={"LOCKED"}>Đã khóa</MenuItem>
                </Select>
            </FormControl>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Seller ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Updated by</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Dialog open={openDialog}>
                        <IconButton onClick={() => {
                            setOpenDialog(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                        <ul style={{ padding: 10 }}>
                            <li><b>ID: </b>{currentShop && currentShop.id}</li>
                            <li><b>Owner ID: </b>{currentShop && currentShop.ownerId}</li>
                            <li><b>Name: </b>{currentShop && currentShop.name}</li>
                            <li><b>Phone: </b>{currentShop && currentShop.phoneNumber}</li>
                            <li><b>FB: </b><a  target="_blank" href={currentShop && currentShop.linkFacebook}>{currentShop && currentShop.linkFacebook}</a></li>
                            <li><b>Link shopee: </b><a target="_blank" href={currentShop && currentShop.linkShopee}>{currentShop && currentShop.linkShopee}</a></li>
                            <li><b>Image: </b><a  target="_blank" href={currentShop && currentShop.img}>{currentShop && currentShop.img}</a></li>
                            <li><b>Status: </b>{currentShop && currentShop.status}</li>
                            <li><b>Created at: </b>{currentShop && currentShop.createdAt}</li>
                            <li><b>Created at: </b>{currentShop && currentShop.updatedAt}</li>
                            <li><b>Updated by: </b>{currentShop && currentShop.updatedBy}</li>
                        </ul>
                    </Dialog>
                    {shops && shops.map((shop) => {
                        return (
                            <>
                                <TableRow>
                                    <TableCell>
                                        {shop.id}
                                    </TableCell>
                                    <TableCell>
                                        {shop.ownerId}
                                    </TableCell>
                                    <TableCell>
                                        {shop.name}
                                    </TableCell>
                                    <TableCell>
                                        {getStatusLabel(shop.status)}
                                    </TableCell>
                                    <TableCell>
                                        {shop.updatedBy}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => {
                                            setCurrentShop(shop)
                                            setOpenDialog(true)
                                        }}>
                                            chi tiết
                                        </Button>
                                        <Button disabled={!(shop.status === 'INACTIVE')} onClick={() => {
                                            handleApproveShop(shop.id);
                                        }}>
                                            duyệt
                                        </Button>
                                        <Button disabled={!(shop.status === 'INACTIVE')} onClick={() => {
                                            handleRejectShop(shop.id);
                                        }}>
                                            từ chối duyệt
                                        </Button>
                                        <Button disabled={!(shop.status === 'ACTIVE')} onClick={() => {
                                            handleLockShop(shop.id)
                                        }}>
                                            khóa
                                        </Button>
                                        <Button disabled={!(shop.status === 'LOCKED')} onClick={() => {
                                            handleApproveShop(shop.id);
                                        }}>
                                            mở khóa
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                    })
                    }
                </TableBody>
            </Table>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePageSizeChange}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                />
            </Box>
        </TableContainer>
    )
};
export default ListShopsTable;
